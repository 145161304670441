import React from "react"
import styled from "styled-components"
import { colours } from "../../style/colours"
import { navigate } from "gatsby"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  margin-bottom: 40px;
  cursor: pointer;

  @media (max-width: 550px) {
    margin-bottom: 50px;
  }
`
const ProductImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;

  @media (max-width: 550px) {
    width: 150px;
    height: 150px;
  }
`
const Name = styled.h3`
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  color: ${colours.maroonDark};
  margin-bottom: 5px;
  max-width: 250px;
  /* height: 48px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* number of lines to show */
  text-transform: capitalize;
`
const Description = styled.p`
  font-size: 14px;
  opacity: 0.8;
  max-width: 250px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 20px;
`
const Price = styled.p`
  font-weight: 600;
  color: ${colours.greenDark};
`
const Button = styled.button`
  background: none;
  color: ${colours.greenLight};
  border: 1px solid ${colours.greenDark};
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease;
  font-size: 15px;

  @media (max-width: 550px) {
    padding: 10px 10px;
    font-size: 15px;
  }

  :hover {
    background: ${colours.greenLight};
    color: white;
  }

  :focus {
    outline: none;
  }

  :disabled {
    border: 1px solid #ddd;
    color: #888;
    
    :hover {
    background: none;
    color: #888;
  }
  }
`

const ProductItem = ({ product, addToCart }) => {
  return (
    <Container>
        <LazyLoadImage
          alt={"product-image"}
          height={150}
          effect="opacity"
          style={{ objectFit: 'contain'}}
          src={product.images.length > 0 ? product.images[0].originalSrc : require('../../images/placeholder.png')}
          width={150}
          onClick={() => navigate(`/shop/${product.handle}`)}
          // onClick={() => console.log(product)}
        />
      
      <Name onClick={() => navigate(`/shop/${product.handle}`)}>
        {product.title.toLowerCase()}
      </Name>
      {/* <Description onClick={() => navigate(`/shop/${product.handle}`)}>
        {product.description.substr(0, 60)}...
      </Description> */}
      {product.variants[0].compareAtPrice && 
        <Price style={{marginBottom: 2, fontSize: 15, textDecoration: 'line-through'}} onClick={() => navigate(`/shop/${product.handle}`)}>
          ${parseFloat(product.variants[0].compareAtPrice).toFixed(2)} TTD
        </Price>
      }
      <Price onClick={() => navigate(`/shop/${product.handle}`)}>
        ${parseFloat(product.priceRange.minVariantPrice.amount).toFixed(2)} TTD
      </Price>
      <Button disabled={!product.availableForSale}  onClick={() => addToCart(product)}>{!product.availableForSale ? 'Out of Stock' : 'Add to Cart'}</Button>
    </Container>
  )
} 

export default ProductItem
