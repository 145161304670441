import React from "react"
import styled from "styled-components"
import { colours } from "../../style/colours"

const OutOfStockBlock = () => (
    <Container>
        <Copy>Currently out of stock.</Copy>
    </Container>
)

export default OutOfStockBlock

const Container = styled.div`
    background: ${colours.maroonDark};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: fit-content;
    margin-bottom: 20px;
`
const Copy = styled.h5`
    text-transform: uppercase;
    color: white;
    margin: 0;
    line-height: 1.5;
`
