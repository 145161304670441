import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql, Link, navigate } from "gatsby"
import { connect } from "react-redux"
import { addToCart, toggleShowCart, updateCartCount } from "../state/app"
import SEO from "../components/seo"
import { colours } from "../style/colours"
import Select from "react-select"
import ProductItem from "../components/products/product-item"
import OutOfStockBlock from "../components/products/out-of-stock-block"
import { convertShopifyId, numberToCurrency } from "../utils/utils"
import axios from 'axios'
import Loader from "react-loader-spinner"

const customStyles = {
  control: (provided, state) => ({
      ...provided,
      height: "56px",
      borderRadius: "1px",
      border: "1px solid #E7E7E7",
      outline: "none",
  }),
  menuList: (provided, state) => ({
      ...provided,
      fontFamily: "Muli",
      fontWeight: 500,
      color: "black",
      padding: 7,
  }),
  multiValueLabel: (provided, state) => ({
      ...provided,
      fontFamily: "Muli",
      fontWeight: "bold",
  }),
  singleValue: (provided, state) => ({
      ...provided,
      fontFamily: "Muli",
      fontWeight: "500",
  }),
  placeholder: (provided, state) => ({
      ...provided,
      fontFamily: "Muli",
  }),
}

const ProductGrid = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  padding: 30px;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
`
const ProductImg = styled.img`
  max-width: 450px;
  max-height: 450px;
  object-fit: contain;
  margin: 0 0 20px 0;

  @media (max-width: 760px) {
    max-width: 350px;
    max-height: 350px;
  }
`
const AllImagesContainer = styled.div`
  display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    //Hide scroll bars

    //Firefox
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }

    @media (max-width: 760px) {
    justify-content: flex-start;
        overflow-x: scroll;
        flex-wrap: nowrap;
    }
`
const ExtraProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-right: 10px;
  cursor: pointer;
  border: ${props => props.selected && "2px solid #5C803E"};

  @media (max-width: 760px) {
    margin-bottom: 50px;
  }

  @media (max-width: 760px) {
    max-width: 80px;
    max-height: 80px;
  }
`
const ProductInfoContainer = styled.div`
  max-width: 500px;
  width: 100%;
`
const Title = styled.h2``
const Price = styled.p`
  font-weight: 600;
  opacity: 0.8;
  font-size: 20px;
`
const Description = styled.p`
  font-size: 16px;
`
const CartButton = styled.button`
  color: white;
  background: ${colours.greenDark};
  border: 1px solid ${colours.greenLight};
  outline: none;
  text-transform: uppercase;
  padding: 8px 15px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  width: 80%;


  :disabled {
    background: #a7a7a7;
    cursor: not-allowed;
    border: 1px solid #a7a7a7;

    :hover {
      background:  #a7a7a7;
  }
  }

  :hover {
    background: ${colours.greenDark};
    color: white;
  }
`
const VariantOptions = styled.div`
    margin-bottom: 25px;
`
const VariantTitle = styled.h5`
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
`
const CTAs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
`
const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 120px;
  margin-right: 35px;

  @media (max-width: 550px) {
    /* margin-right: 5px; */
  }
`
const QuantityButton = styled.button`
  background: #f2f2f2;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  color: ${colours.maroonDark};
  font-size: 18px;
  border: none;
  font-weight: 500;
  font-family: "Work Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :focus {
    outline: none;
  }
`
const BreadCrumb = styled.p``
const QuantityCount = styled.label`
  font-weight: 500;
  font-size: 20px;
  color: ${colours.greenDark};
  font-family: "Muli";
`
const RelatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SectionTitle = styled.h2`
  margin-bottom: 30px;
`
const ProductsContainer = styled.section`
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: 33.3% 33.3% 33.3%; */

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

class ProductPage extends Component {
  state = {
    currentImg: "",
    quantity: 1,
    loadingRelated: true,
    relatedProducts: [],
    selectedVariant: "",
    variantTitle: "",
    variants: [],
    options: [],
    selectedOptions: [],
    isReady: false,
    outOfStock: false,
    maximumQuantity: null,
    loadingQuantity: false,
    activeImage: "",
  }

  componentDidMount() {
    this.pageSetup()
    this.setupRelatedItems()
    this.setupVariants()
    this.getVariantOptions()
  }

  productHasVariants = () => {
    const { variants } = this.props.data.product

    if (variants.length === 1 && variants[0].title === "Default Title") {
        return false
    }

    return true
}

setupVariants = () => {
    
    const variants = this.props.data.product.variants.map(item => ({
        value: item.shopifyId,
        label: item.title,
        price: item.price,
        available: item.availableForSale,
        image: item.image.originalSrc
    }))

    //Check if the product has variants
    if (!this.productHasVariants()) {
        //Get quantities via shopify API
        this.getVariantQuantity(convertShopifyId(variants[0].value))
        this.setState({  selectedVariant: variants[0] })
    return
    }

    //Only set selected variant if a trade is not occuring
    this.setState({ variants, selectedVariant: this.state.isTrade ? null : variants[0] })
}

onSelectChange = (value, index) => {

  let currentOptions = this.state.selectedOptions

  //Clear other options if selecting first variant
  if (index === 0) {
      currentOptions = []
  }

  currentOptions[index] = value.label
  this.setState({ selectedOptions: currentOptions }, () => {
      if (this.isVariantReady()) {
          //MATCH THE VARIANT TO THE OPTIONS
          this.getSelectedVariant()
      }
  })
}

isVariantReady = () => {
  const { selectedOptions, options } = this.state
  var allFilled = true

  for (let i = 0; i < options.length; i++) {
      if (typeof selectedOptions[i] === "undefined") {
          allFilled = false
      }
  }

  this.setState({ isReady: allFilled })
  return allFilled
}

getSelectedVariant = () => {
  const { selectedOptions, variants } = this.state
  const title = selectedOptions.join(" / ")
  this.setState({ variantTitle: title })

  //Loop through all variants and find a match for the label
  for (let i = 0; i < variants.length; i++) {
      if (title === variants[i].label) {
          //Get the quantities for the variant
          this.getVariantQuantity(convertShopifyId(variants[i].value))
          
          this.setState({ quantity: 1, selectedVariant: variants[i], activeImage: variants[i].image, outOfStock: !variants[i].available ? true : false })
      }
  }
}

getVariantQuantity = async (variantId) => {
  this.setState({ loadingQuantity: true })
  try {
      let postRes = await axios.post('/.netlify/functions/get-variant-quantity' , 
      JSON.stringify({
          variantId: variantId,
      }))
      this.setState({ loadingQuantity: false })
      if (postRes.data.id) {
          //Got quantities
          const { inventory_quantity } = postRes.data 
          const outOfStock = inventory_quantity > 0 ? false : true

          this.setState({ outOfStock: outOfStock, maximumQuantity: inventory_quantity })
      }else {
          alert("There was a problem checking quantities for this device. Please try again.")
          this.setState({ outOfStock: true })
      }
  }catch(err){
      console.log(err)
  }
}

setVariantPicture = i => {
  const { product } = this.props.data
  

  if (product.images[i]) {
      this.setState({ activeImage: product.images[i].originalSrc })
  } else {
      this.setState({ activeImage: product.images[0].originalSrc })
  }
}

getVariantOptions = () => {
  const { product } = this.props.data
  let options = []

  options = product.options.map(el => ({
      name: el.name,
      options: el.values.map(val => ({
          value: val,
          label: val,

      })),
  }))

  this.setState({ options: options })
}

checkIfVariantAvailable = (option) => {

  const { selectedOptions, variants, options } = this.state
  let available = true

  //Make sure option one is selected
  if (selectedOptions.length === 0 && options.length !== 1) {
      return true
  }

  let title = `${selectedOptions.join(" / ")} / ${option.value}`

  //Remove the extra characters if theres only one options
  if (options.length === 1) {
      title = option.value
  }



  //Loop through all variants and find a match for the label
  for (let i = 0; i < variants.length; i++) {
      if (title === variants[i].label) {
          available = variants[i].available
      }
  }

  return available
}

addCheckoutToSessionStorage = (checkoutObj) => {
  if (typeof window !== 'undefined') {
      // do your checks; this will be re-invoked on client entry
      sessionStorage.setItem('checkout', JSON.stringify(checkoutObj));
  }
}

  pageSetup = () => {
    const { product } = this.props.data

    //Set the initial image
    this.setState({ currentImg: product.images[0].originalSrc })
  }

  handleImageSelect = img => {
    this.setState({ currentImg: img })
  }

  handleAddToCart = product => {
    const { dispatch, shoppingCart } = this.props
    const { quantity, selectedVariant } = this.state

    let productsToReturn = shoppingCart

    //Check if the product is already in the cart
    const obj = shoppingCart.find(el => el.shopifyId === product.shopifyId)

    if (obj) {
      //find the index and adjust the count
      let index = shoppingCart.indexOf(obj)
      productsToReturn[index].count = quantity

      //Dispatch new cart with updated counts to state
      dispatch(updateCartCount(productsToReturn))
      dispatch(toggleShowCart(true))
    } else {
      const productWithFields = {
        ...product,
        count: quantity,
        variantId: convertShopifyId(selectedVariant.value)
      }
      dispatch(addToCart(productWithFields))
      dispatch(toggleShowCart(true))

      //Remove the cart after 2 seconds
      setTimeout(() => {
        dispatch(toggleShowCart(false))
      }, 2000)
    }
  }

  handleMinus = () => {
    const { quantity } = this.state

    if (quantity === 1) {
      return
    } else {
      this.setState({ quantity: quantity - 1 })
    }
  }

  handleAdd = () => {
    const { quantity, maximumQuantity } = this.state

    if (maximumQuantity && quantity >= maximumQuantity) {
      return
    }

    this.setState({ quantity: quantity + 1 })
  }

  setupRelatedItems = () => {
    const collections = this.props.pageContext.collections.edges
    const { product } = this.props.data

    //Loop through and find the first collection this product belongs to
    for (let i = 0; i < collections.length; i++) {
      //Getting product titles only
      const productTitles = this.getProductTitles(collections[i])

      if (productTitles.includes(product.title)) {
        const uniqueRelated = this.removeActiveProduct(
          collections[i].node.products,
          product.title
        )
        const fewRelated = this.getFirstThreeProducts(uniqueRelated)
        this.setState(
          {
            relatedProducts: fewRelated,
            loadingRelated: false,
          })
        return
      }
    }

    //NO related products found
    this.setState({ loadingRelated: false })
  }

  getProductTitles = collections => {
    return collections.node.products.map(collection => collection.title)
  }

  removeActiveProduct = (relatedProducts, title) => {
    return relatedProducts.filter(product => product.title !== title)
  }

  getFirstThreeProducts = relatedProducts => {
    return relatedProducts.slice(0, 3)
  }

  render() {
    const { product } = this.props.data
    const { 
      currentImg,
      loadingRelated,
      relatedProducts,
      options,
      variants,
      outOfStock,
      quantity,
      isReady,
      loadingQuantity,
      selectedVariant,
    } = this.state
    
    return (
      <Layout>
        <SEO title={product.title} />
        <ProductGrid>
          <ImagesContainer>
            <ProductImg src={currentImg} />
            <AllImagesContainer>
              {product.images.map((image, index) => (
                <ExtraProductImage
                  key={index}
                  onClick={() => this.handleImageSelect(image.originalSrc)}
                  selected={currentImg === image.originalSrc}
                  src={image.originalSrc}
                />
              ))}
            </AllImagesContainer>
          </ImagesContainer>
          <ProductInfoContainer>
            <BreadCrumb>
              Home / <Link to="/shop">Back to shop</Link>{" "}
            </BreadCrumb>
            <Title>{product.title}</Title>
            <Price>{numberToCurrency(selectedVariant.price * quantity)}</Price>
            {product.availableForSale ?
                variants.length > 0 && 
                    <VariantOptions>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                style={{ marginBottom: 20 }}
                            >
                                <VariantTitle>
                                    Select a {option.name}
                                </VariantTitle>
                                <Select
                                    placeholder="Please select"
                                    isSearchable={false}
                                    styles={customStyles}
                                    isOptionDisabled={(option) => !this.checkIfVariantAvailable(option)}
                                    options={option.options}
                                    onChange={val =>
                                        this.onSelectChange(val, index)
                                    }
                                />
                            </div>
                        ))}
                    </VariantOptions>
            :
            <OutOfStockBlock />
            }
            <CTAs>
              <QuantityContainer>
                <QuantityButton onClick={() => this.handleMinus()}>
                  -
                </QuantityButton>
                <QuantityCount>{quantity}</QuantityCount>
                <QuantityButton onClick={() => this.handleAdd()}>
                  +
                </QuantityButton>
              </QuantityContainer>

            <CartButton
                  noWidth
                  full={loadingQuantity ? true : false}
                  disabled={(variants.length > 0 && !isReady) || outOfStock || loadingQuantity}
                  onClick={() => this.handleAddToCart(product)}
                  black
              >
                  {loadingQuantity ? <Loader type="Oval" height={15} width={15} color="white" /> : outOfStock ? 'Out of Stock' : 'Add to Cart'}
                  
              </CartButton>
            </CTAs>
            <Description
              dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
            />
          </ProductInfoContainer>
        </ProductGrid>
        <hr style={{ opacity: 0.3 }} />
        {!loadingRelated && relatedProducts.length && (
          <RelatedContainer>
            <SectionTitle>Related Products</SectionTitle>
            <ProductsContainer>
              {relatedProducts.map((product, index) => (
                <ProductItem
                  key={index}
                  product={product}
                  addToCart={this.handleAddToCart}
                />
              ))}
            </ProductsContainer>
          </RelatedContainer>
        )}
      </Layout>
    )
  }
}

export default connect(
  state => ({
    shoppingCart: state.app.shoppingCart,
  }),
  null
)(ProductPage)

export const ProductQuery = graphql`
  query($id: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      shopifyId
      priceRange {
          maxVariantPrice {
              amount
          }
          minVariantPrice {
              amount
          }
      }
      descriptionHtml
      availableForSale
      productType
      images {
          originalSrc
      }
      variants {
          shopifyId
          title
          price
          availableForSale
          image {
              originalSrc
          }
      }
      options {
          name
          values
          shopifyId
      }
    }
  }
`
